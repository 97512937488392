<script>
import { authMethods } from "@/state/helpers"
import { required, email } from "vuelidate/lib/validators"
import { userService } from "../../../helpers/backend/user.service"

export default {
  data() {
    return {
      form: {
        email: "",
      },
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      showDismissibleAlert: false,
    }
  },
  validations: {
    email: { required, email }
  },
  created() {
    document.body.classList.add("auth-body-bg")
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true
      this.busy = true
      userService.forgotPassword(this.form).then(
        (data) => {
          if (data != null) {
            alert("Success reset passsword. Please check your email")
            this.reset()
            this.showDismissibleAlert = true
            this.error = ""
            this.$router.push(
              this.$route.query.redirectFrom || { name: "home" }
            )
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Reset Password. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.email = null

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <b-alert
                          :variant="error == '' ? 'success' : 'danger'"
                          dismissible
                          fade
                          :show="showDismissibleAlert"
                          @dismissed="showDismissibleAlert = false"
                        >
                          <div v-if="error != ''">
                            {{ error }}
                          </div>
                          <div v-else>
                            Success reset passsword. Please check your email
                          </div>
                        </b-alert>
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="240"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Reset Password</h4>
                        <p class="text-muted">Reset your password to VMCore.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="isResetError"
                          class="mb-4"
                          variant="danger"
                          dismissible
                          >{{ error }}</b-alert
                        >

                        <b-overlay :show="busy" rounded="lg" opacity="0.6">
                          <form
                            class="form-horizontal"
                            @submit.prevent="tryToReset"
                          >
                            <div class="form-group auth-form-group-custom mb-4">
                              <i
                                class="ri-mail-line auti-custom-input-icon"
                              ></i>
                              <label for="useremail">Email</label>
                              <input
                                v-model="form.email"
                                type="email"
                                class="form-control"
                                id="useremail"
                                placeholder="Enter email"
                                :class="{
                                  'is-invalid': submitted && $v.email.$error,
                                }"
                              />
                              <div
                                v-if="submitted && $v.email.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.email.required"
                                  >Email is required.</span
                                >
                                <span v-if="!$v.email.email"
                                  >Please enter valid email.</span
                                >
                              </div>
                            </div>

                            <div class="mt-4 text-center">
                              <button
                                class="
                                  btn btn-primary
                                  w-md
                                  waves-effect waves-light
                                "
                                type="submit"
                              >
                                Reset
                              </button>
                            </div>
                          </form>
                        </b-overlay>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Log in</router-link
                          >
                        </p>
                        <p>© 2020 VMI. Created by DATIS</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>